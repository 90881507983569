<template>
  <v-container fluid pa-0 fill-height>
    <v-row no-gutters class="text-center justify-center fill-height">
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="3"
        lg="3"
        xl="3"
        class="align-self-center"
      >
        <v-row no-gutters class="text-center justify-center mt-6">
          <v-col cols="auto">
            <v-img
              src="../assets/SPPlusMetropolisLogo.svg"
              width="100%"
              height="100%"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row no-gutters class="text-center justify-center my-6">
          <v-col cols="auto">
            <span class="text-h5">Forgot Your Password</span>
          </v-col>
        </v-row>
        <v-card tile flat class="pa-4">
          <v-row no-gutters class="text-center justify-center mt-6">
            <v-col><span> Enter email to send reset password link </span></v-col>
          </v-row>
          <v-row no-gutters class="text-center justify-center mt-6">
            <v-col>
              <v-text-field
                outlined
                 :rules="emailRules"
                 hide-details="auto"
                label="Email address"
                v-model="email"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-6">
            <v-col>
              <v-btn text @click="onClickCancel()"
                >Cancel</v-btn
              >
            </v-col>
            <v-col>
              <v-btn block class="elevation-0 primary" @click="resetPassword()" :disabled="email == null || email == ''"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-6">
            <v-col>
              <span class="text-caption">Already a member ?</span>
              <a class="text-caption cursor ml-2" @click="navigateToLogin()"
                >Log in</a
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-footer absolute>
      <v-col class="text-right" cols="12">
        <span class="text-caption">© 2022 DIVRT INC. All Rights Reserved.</span>
      </v-col>
    </v-footer> -->
     <v-snackbar v-model="showSnackBar" :color="snackBarColor">
      {{ snackBarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="showSnackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import * as API from "@/api/api";
export default {
  name: "ForgotPasswordView",
  components: {},
  data() {
    return {
      email: "",
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      snackBarText: "",
      snackBarColor: "",
      showSnackBar: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("LOGIN");
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onClickCancel() {
      this.$router.replace({path:"/login"})
    },
    navigateToLogin(){
      this.$router.replace({path:"/login"})
    },
    async resetPassword(){
      let result = await API.resetPassword({me:this.email, provider:1, source:"CA"});

      this.showSnackBar = true
      this.snackBarText = result?.data.message;
      this.snackBarColor = !result?.data.status ? "red" : "green";
      result?.data?.status && result.data.status ? setTimeout(() => this.navigateToLogin(), 3000) : ""

      this.$notify({
          group: "divrt",
          title:
            result?.data?.status && result.data.status
              ? "Success"
              : "Error",
          text: result?.data?.message
            ? result.data.message
            : "",
          type:
            result?.data?.status && result.data.status
              ? "success"
              : "error",
        });
        
    }
  },
};
</script>
